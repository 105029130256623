(function($, window, document, undefined) {
  $(document).ready(function() {
    function getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
    // ---------------------------------------------------------------------------------------------------- Header

    $(".mobileMenuBtn").click(function() {
      $(".mobileNav").slideDown(200);
    });
    $(".mobileMenuBtn2").click(function() {
      $(".mobileNav").slideUp(200);
    });

    // ---------------------------------------------------------------------------------------------------- FAQs
    let $faqItems = $(".faqsContainer .faqItem");
    let $questions = $faqItems.find(".question");
    $questions.click(function() {
      var faqItem = $(this).parent();
      $faqItems.removeClass("open");
      if (faqItem.find(".answer").is(":visible")) {
        faqItem.find(".answer").slideUp(250);
        //faqItem.removeClass("open");
      } else {
        $(".faqsContainer .faqItem .answer").slideUp(250);
        faqItem.addClass("open");
        faqItem.find(".answer").slideDown(250);
      }
    });

    // ---------------------------------------------------------------------------------------------------- Video Modal
    $("#videoModal").on("show.bs.modal", function(event) {
      player.playVideo();
    });

    $("#videoModal").on("hide.bs.modal", function(event) {
      stopVideo();
    });

    $("#videoToggle").on("click", function(e) {
      e.preventDefault();
      $("#videoSlide").toggleClass("active");

      if ($("#videoSlide").hasClass("active")) {
        $("html, body").animate(
          {
            scrollTop: $(this).offset().top
          },
          1000
        );
        player.playVideo();
      } else {
        stopVideo();
      }
    });

    // ---------------------------------------------------------------------------------------------------- template_two_columns
    function template_two_columns_SetHeights() {
      var template_two_columns = $(".template_two_columns");
      for (var i = 0; i < template_two_columns.length; i++) {
        var columnBodys = $(template_two_columns[i]).find(".columnBody");
        columnBodys.css("min-height", "1px");
        if (!$(".visible-xs").is(":visible")) {
          var max = 0;
          for (var j = 0; j < columnBodys.length; j++) {
            var item = $(columnBodys[j]);
            if (item.height() > max) max = item.height();
          }
          max += 20;
          columnBodys.css("min-height", max + "px");
        }
      }
    }
    template_two_columns_SetHeights();

    // ---------------------------------------------------------------------------------------------------- equal Height positioning
    function equalheight(container) {
      var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        $el,
        topPosition = 0;
      $(container).each(function() {
        $el = $(this);
        $($el).height("auto");
        topPostion = $el.position().top;

        if (currentRowStart != topPostion) {
          for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
          }
          rowDivs.length = 0; // empty the array
          currentRowStart = topPostion;
          currentTallest = $el.height();
          rowDivs.push($el);
        } else {
          rowDivs.push($el);
          currentTallest =
            currentTallest < $el.height() ? $el.height() : currentTallest;
        }

        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
          rowDivs[currentDiv].height(currentTallest);
        }
      });
    }
    equalheight(".vertical_center");

    // ---------------------------------------------------------------------------------------------------- do_setHeightFromParent
    function do_setHeightFromParent() {
      var setHeightFromParent = $(".setHeightFromParent");
      setHeightFromParent.height("");
      for (var i = 0; i < setHeightFromParent.length; i++) {
        var temp = $(setHeightFromParent[i]);
        temp.height(temp.parent().height() + "px");
      }
    }
    do_setHeightFromParent();
    setTimeout(function() {
      do_setHeightFromParent();
    }, 100);

    // ---------------------------------------------------------------------------------------------------- format contact gravity form
    var gfields = $(".gfield");
    for (var i = 0; i < gfields.length; i++) {
      var gfield = $(gfields[i]);
      var mediumElement = gfield.find(".medium");
      if (mediumElement.length > 0) {
        gfield.addClass("gfield_float50");
      }
    }

    function check_gfields_mobile() {
      var gfield_float50s = $(".gfield_float50");
      if (gfield_float50s.length < 0) return;
      if ($(window).width() < 700) {
        if (!gfield_float50s.hasClass("gfield_float50_mobile"))
          gfield_float50s.addClass("gfield_float50_mobile");
      } else {
        if (gfield_float50s.hasClass("gfield_float50_mobile"))
          gfield_float50s.removeClass("gfield_float50_mobile");
      }
    }
    check_gfields_mobile();

    // ---------------------------------------------------------------------------------------------------- on window resize
    $(window).on("resize", function() {
      template_two_columns_SetHeights();
      do_setHeightFromParent();
      check_gfields_mobile();
      equalheight(".vertical_center");
      //stickyNav();
    });

    // ---------------------------------------------------------------------------------------------------- on window scroll
    $(window).scroll(function() {
      //stickyNav();
    });

    // ---------------------------------------------------------------------------------------------------- on window scroll
    $(window).load(function() {
      equalheight(".vertical_center");
    });
  }); // end $(document).ready()
})(jQuery, window, document);
